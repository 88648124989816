.logo {
  width: auto;
  height: 50px;
  padding: 10px;
}

.ruv-recommendation-logo::before {
  content: '';
  height: 100px;
  display: block;
  background-size: auto 100px;
  background-image: url('../assets/images/ruv-recommendation.png');
  background-repeat: no-repeat;
  background-position: center;
}

.growmytree-logo::before {
  content: '';
  height: 80px;
  display: block;
  background-size: auto 80px;
  background-image: url('../assets/images/growmytree.svg');
  background-repeat: no-repeat;
}

@media only screen and (max-width: 576px) {
  .growmytree-logo::before {
    content: '';
    height: 80px;
    display: block;
    background-size: auto 80px;
    background-image: url('../assets/images/growmytree.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
