// KRAVAG color scheme
$kravag-primary: #ffffff;
$kravag-secondary: #000000;
$kravag-highlight: #e2001a;
$kravag-gray-1: #c6c3ac;
$kravag-gray-2: #9d9b89;

body[theme='kravag'] {
  --font-family: Arial, sans-serif;
  --primary: #{$kravag-secondary};
  --secondary: #{$kravag-highlight};

  --kendo-border-radius-lg: 5px;
  --kendo-border-radius-md: 4px;
  --button-text-color: #{$kravag-primary};
  --button-solid-hover-bg-color: #{$kravag-highlight};
  --kendo-button-solid-secondary-active-hover-bg: #{$kravag-highlight};
  --kendo-button-solid-secondary-active-hover-border: #{$kravag-highlight};

  .welcome {
    font-size: 28px;
    color: #{$kravag-highlight};
  }
}
