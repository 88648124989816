@font-face {
  font-family: 'Marselis';
  src: url('../assets/fonts/FFMarselisforRuV.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Marselis';
  src: url('../assets/fonts/FFMarselisforRuV-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Marselis-Slab';
  src: url('../assets/fonts/FFMarselisforRuVSlab.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Marselis-Slab';
  src: url('../assets/fonts/FFMarselisforRuVSlab-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
