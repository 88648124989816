body[theme='rheingau'] {
  --font-family: Arial, sans-serif;
  --primary: #0066b3;
  --secondary: #ff6600;

  --kendo-border-radius-lg: 0px;
  --kendo-border-radius-md: 0px;
  --button-text-color: white;
  --button-solid-hover-bg-color: #bd4f00;
  --kendo-button-solid-secondary-active-hover-bg: #994000;
  --kendo-button-solid-secondary-active-hover-border: #994000;
}
