@use 'colors';

body[theme='ruv'] {
  --font-family: 'Marselis', Arial, sans-serif;
  --primary: #{colors.$darkblue};
  --secondary: #{colors.$cyan-2};
  --paragraph-margin-bottom-size: 16px;
  --highlighted-font-color: #{colors.$darkorange};
  --link-hover-color: #{colors.$cyan-1};
  --checkbox-link-color: #{colors.$cyan-1};

  a:hover {
    color: var(--link-hover-color);
  }

  a,
  a:active {
    color: var(--primary);
  }
  a:hover,
  a:active {
    text-decoration: underline !important;
  }
  a.checkbox-link {
    color: var(--checkbox-link-color);
  }
}

.ruv-ol {
  display: grid;
  grid-template-columns: 2fr 10fr;
  align-items: center;
}

.ruv-li-pic {
  background-repeat: no-repeat;

  height: 3em;
  width: 3em;
  margin-bottom: var(--paragraph-margin-bottom-size);
}

.ruv-li-1 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/1-versatz-mint.svg');
}
.ruv-li-2 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/2-versatz-mint.svg');
}
.ruv-li-3 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/3-versatz-mint.svg');
}
.ruv-li-4 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/4-versatz-mint.svg');
}
.ruv-li-5 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/5-versatz-mint.svg');
}
.ruv-li-6 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/6-versatz-mint.svg');
}
.ruv-li-7 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/7-versatz-mint.svg');
}
.ruv-li-8 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/8-versatz-mint.svg');
}
.ruv-li-9 {
  @extend .ruv-li-pic;
  background-image: url('../assets/images/ol/9-versatz-mint.svg');
}
