@use 'colors';

@font-face {
  font-family: 'RuVIcon';
  src: url('assets/fonts/RuVIcon.ttf') format('truetype');
}

.ruv-icon {
  display: inline-block;
  font-family: 'RuVIcon';
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ruv-icon:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ruv-icon.ruv-icon-large {
  font-size: 32px;
  line-height: 32px;
}

.ruv-icon.ruv-icon-medium {
  font-size: 20px;
  line-height: 20px;
}

.ruv-icon.ruv-icon-small {
  font-size: 16px;
  line-height: 16px;
}

.ruv-icon.ruv-icon-tiny {
  font-size: 14px;
  line-height: 14px;
}

.ruv-icon.ruv-icon-green {
  color: colors.$green-5;
}

// Special glyphs

.ruv-error-icon {
  display: inline-flex;
  font-family: 'RuVIcon';
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
  color: colors.$red-2;
  padding-top: 16px;
  padding-right: 8px;
}

.ruv-error-icon:before {
  content: '\e903';
}

.ruv-success-icon {
  display: inline-block;
  font-family: 'RuVIcon';
  font-style: normal;
  font-size: 16px;
  line-height: 40;
  color: colors.$green-5;
  padding-top: 16px;
  padding-right: 8px;
}

.ruv-success-icon:before {
  content: '\e941';
}

// Icon glyphs

.ruv-icon.ruv-info:before {
  content: '\e900';
}

.ruv-icon.ruv-info-filled:before {
  content: '\e901';
}

.ruv-icon.ruv-warning:before {
  content: '\e903';
}

.ruv-icon.ruv-warning-filled:before {
  content: '\e902';
}

.ruv-icon.ruv-help:before {
  content: '\e904';
}

.ruv-icon.ruv-help-filled:before {
  content: '\e905';
}

.ruv-icon.ruv-phone:before {
  content: '\e911';
}

.ruv-icon.ruv-mobile:before {
  content: '\e954';
}

.ruv-icon.ruv-room:before {
  content: '\e93c';
}

.ruv-icon.ruv-account-balance:before {
  content: '\e923';
}

.ruv-icon.ruv-identity:before {
  content: '\e92c';
}

.ruv-icon.ruv-done:before {
  content: '\e941';
}

.ruv-icon.ruv-block:before {
  content: '\e948';
}

.ruv-icon.ruv-calendar:before {
  content: '\e94e';
}

.ruv-icon.ruv-work:before {
  content: '\e958';
}

.ruv-icon.ruv-mail:before {
  content: '\e95a';
}

.ruv-icon.ruv-search:before {
  content: '\e972';
}

.ruv-icon.ruv-arrow-up:before {
  content: '\e977';
}

.ruv-icon.ruv-arrow-down:before {
  content: '\e978';
}

.ruv-icon.ruv-arrow-left:before {
  content: '\e979';
}

.ruv-icon.ruv-arrow-right:before {
  content: '\e97a';
}

.ruv-icon.ruv-refresh:before {
  content: '\e96d';
}

.ruv-icon.ruv-more:before {
  content: '\e969';
}

.ruv-icon.ruv-menu:before {
  content: '\e96b';
}

.ruv-icon.ruv-communication:before {
  content: '\e95c';
}

.ruv-icon.ruv-eye-open:before {
  content: '\e994';
}

.ruv-icon.ruv-eye-closed:before {
  content: '\e9a0';
}

.ruv-icon.ruv-cross-circle-filled:before {
  content: '\e985';
}

.ruv-icon.ruv-plus:before {
  content: '\e97C';
}

.ruv-icon.ruv-minus:before {
  content: '\e96C';
}

.ruv-icon.ruv-download:before {
  content: '\E96F';
}
