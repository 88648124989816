$max-cols: 12;

.container {
  --container-padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--container-padding);
}

.centering {
  box-sizing: border-box;
  flex: 1;
  width: 20%;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 576px) {
    width: 75%;
  }

  @media only screen and (min-width: 768px) {
    width: 60%;
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    width: 33%;
  }

  @media only screen and (min-width: 1600px) {
    width: 22%;
  }
}

/* EXPERIMENTAL START */
body.experimental-layout .centering {
  width: min(100%, 576px);
}
/* EXPERIMENTAL END */

$max-cols: 12;

.flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &--row {
    flex-direction: row;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--align-items-start {
    align-items: flex-start;
  }

  &--align-items-center {
    align-items: center;
  }

  &--align-items-end {
    align-items: flex-end;
  }

  &--align-items-stretch {
    align-items: stretch;
  }

  &--fixed-size {
    flex: 0 0 auto;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat($max-cols, 1fr);
  grid-auto-flow: row dense;
  grid-gap: 4px;
}

@media only screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

@for $i from 1 through $max-cols {
  .col-#{$i} {
    grid-column: auto/span $i;
  }
}

.noLineBreak {
  white-space: nowrap;
}

.no-padding {
  padding: 0 !important;

  &--top {
    padding-top: 0 !important;
  }

  &--right {
    padding-right: 0 !important;
  }

  &--bottom {
    padding-bottom: 0 !important;
  }

  &--left {
    padding-left: 0 !important;
  }
}

.no-margin {
  margin: 0 !important;

  &--top {
    margin-top: 0 !important;
  }

  &--right {
    margin-right: 0 !important;
  }

  &--bottom {
    margin-bottom: 0 !important;
  }

  &--left {
    margin-left: 0 !important;
  }
}

.margin--top--25 {
  margin-top: 25px;
}

.margin--top--15 {
  margin-top: 15px;
}

.margin--top--5 {
  margin-top: 5px;
}

.margin--left--15 {
  margin-left: 15px;
}

.margin--top--10 {
  margin-top: 10px;
}

.no-margin-bottom {
  margin-bottom: 0;
}
