@use 'colors';

body[theme='bbbank'] {
  --font-family: Arial, sans-serif;
  --primary: #005ca9;
  --secondary: #005ca9;

  --kendo-button-border-width: 4px;
  --kendo-border-radius-lg: 0px;

  --kendo-button-solid-secondary-text: white;
  --kendo-button-solid-secondary-bg: var(--secondary);
  --kendo-button-solid-secondary-border: var(--secondary);

  --kendo-button-solid-secondary-focus-text: var(--primary);
  --kendo-button-solid-secondary-focus-bg: white;
  --kendo-button-solid-secondary-focus-border: var(--primary);

  --kendo-button-solid-secondary-hover-text: var(--primary);
  --kendo-button-solid-secondary-hover-bg: white;
  --kendo-button-solid-secondary-hover-border: var(--primary);

  --kendo-button-solid-secondary-active-text: var(--primary);
  --kendo-button-solid-secondary-active-bg: white;
  --kendo-button-solid-secondary-active-border: var(--primary);

  --kendo-button-solid-secondary-disabled-text: #{colors.$gray-4};
  --kendo-button-solid-secondary-disabled-bg: #{colors.$gray-2};
  --kendo-button-solid-secondary-disabled-border: #{colors.$gray-2};

  --kendo-button-solid-secondary-active-hover-text: var(--primary);
  --kendo-button-solid-secondary-active-hover-bg: white;
  --kendo-button-solid-secondary-active-hover-border: var(--primary);

  --kendo-button-solid-secondary-focus-hover-text: var(--primary);
  --kendo-button-solid-secondary-focus-hover-bg: white;
  --kendo-button-solid-secondary-focus-hover-border: var(--primary);
}
